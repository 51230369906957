import React from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from "../hooks/useAuth";
import FMFProgressBar from "./FMFProgressBar";
import FMFTracker from "./FMFTracker";
import {useExams} from "../hooks/useExams";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as classNames from "classnames";

export default function Navbar() {
  const {user, logout} = useAuth();
  const {getCombinedExamsResults} = useExams();

  var logoutLink = '';
  var viewCertificateLink = '';
  var progressBar = '';
  var tracker = '';
  let completedCount = 0;
  let examsCount = 0;
  let progressScore = 0;

  const combinedExamsResults = getCombinedExamsResults();
  let navClasses = ['row', 'align-items-center'];

  if (user) {
    navClasses.push('logged-in');
    logoutLink = <a href="#" className="btn btn-sm btn-outline-secondary" onClick={logout}>
      Log Out
    </a>;
  }
  else {
    navClasses.push('logged-out');
  }

  if (combinedExamsResults.dataLoaded) {
    completedCount = combinedExamsResults.completedExamsCount;
    examsCount = combinedExamsResults.examsCount;
    if (combinedExamsResults.introductionVideo != null) {
      examsCount += 1;
      if (combinedExamsResults.introductionVideo.completed) {
        completedCount += 1;
      }
    }

    if (completedCount === 0) {
      navClasses.push('nothing-completed');
    }

    progressScore = combinedExamsResults.progressScore;
    progressBar = <span className="progress-wrapper"><FMFProgressBar completed={progressScore}/></span>;
    tracker = <span className="tracker-wrapper col-lg-4 d-lg-flex d-none">Progress: <span className="tracker-image"><FMFTracker
      steps={examsCount} completed={completedCount}/></span></span>;
    viewCertificateLink = <Link to="/certification" className="view-certificate-link"><FontAwesomeIcon
      icon={faFilePdf}/> View Certificate</Link>;
  }


  return (
    <nav className={classNames(navClasses)}>
      {tracker}
      <span className="col-12 col-lg-8 ms-auto">
        {progressBar}
        <ul className="nav-links">
        {viewCertificateLink && (
          <li>{viewCertificateLink}</li>
        )}
        {logoutLink && (
        <li>{logoutLink}</li>
        )}
      </ul>
      </span>
    </nav>
  )
}