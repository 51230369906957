import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import ExamsService from "../services/exams.service";

const ExamsContext = createContext(null);
const EXAM_PASS_MARK = 50;

export const ExamsProvider = ({children}) => {
  const [examsData, setExamsData] = useState(null);
  const value = {
    getIntroductionVideo: getIntroductionVideo,
    getExams: getExams,
    getResults: getResults,
    getCombinedExamsResults: getCombinedExamsResults,
    hasError: hasError,
    refreshData: refreshData,
  };

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    ExamsService.getExams().then(
      (response) => {
        setExamsData(response.data.data);
      },
      (error) => {
        setExamsData(false);
      }
    );
  }

  function getExams() {
    if (examsData == null || examsData === false) {
      return null;
    } else {
      return examsData.exams;
    }
  }

  function getResults() {
    if (examsData == null || examsData === false) {
      return null;
    } else {
      return examsData.results;
    }
  }

  function getIntroductionVideo() {
    if (examsData == null || examsData === false) {
      return null;
    } else {
      return examsData.introductionVideo;
    }
  }

  function getCombinedExamsResults() {
    const exams = getExams();
    const results = getResults();
    const introductionVideo = getIntroductionVideo();
    let combinedExamsResults = [];
    let incompleteExamIds = [];
    let completedCount = 0;
    let attemptedExerciseCount = 0;
    let progressScore;
    let attemptedScoreTotal = 0;
    if (exams != null) {
      exams.forEach(function (exam, index, examsArr) {
        let completed = false;

        let theoryResult = null;
        let practicalResult = null;
        if (results != null) {
          if (results.theory.hasOwnProperty(exam.id)) {
            theoryResult = results.theory[exam.id];
          }

          if (results.practical.hasOwnProperty(exam.id)) {
            practicalResult = results.practical[exam.id];
          }
        }

        completed = (theoryResult != null && theoryResult.taken === true && theoryResult.score >= EXAM_PASS_MARK) && (!exam.includePractical || (practicalResult != null && practicalResult.taken === true && practicalResult.score >= EXAM_PASS_MARK));
        if (theoryResult != null && theoryResult.taken === true) {
          attemptedScoreTotal += theoryResult.score;
          attemptedExerciseCount += 1;
        }
        if (exam.includePractical && practicalResult != null && practicalResult.taken === true) {
          attemptedScoreTotal += practicalResult.score;
          attemptedExerciseCount += 1;
        }
        if (completed) {
          completedCount += 1;
        }

        let examData = {
          exam: exam,
          theoryResult: theoryResult,
          practicalResult: practicalResult,
          completed: completed,
          attempted: theoryResult != null && practicalResult != null,
          completedAllPrerequisites: true,
        };
        combinedExamsResults.push(examData);
        if (!completed) {
          incompleteExamIds.push(exam.id);
        }
      });

      combinedExamsResults.forEach(function (combinedExamResult, index, combinedExamResultArr) {
        if (combinedExamResult.exam.prerequisiteExams.length > 0) {
          let incompletePrerequisites = combinedExamResult.exam.prerequisiteExams.filter(value => incompleteExamIds.includes(value));
          if (incompletePrerequisites.length > 0) {
            combinedExamResult.completedAllPrerequisites = false;
          }
        }
      });
    }

    progressScore = 0;
    if (attemptedExerciseCount > 0) {
      progressScore = Math.round(attemptedScoreTotal / attemptedExerciseCount);
    }

    return {
      dataLoaded: exams != null,
      introductionVideo: introductionVideo,
      examResults: combinedExamsResults,
      examsCount: combinedExamsResults.length,
      completedExamsCount: completedCount,
      progressScore: progressScore,
    };
  }

  function hasError() {
    return examsData === false;
  }

  return <ExamsContext.Provider value={value}>{children}</ExamsContext.Provider>;
};

export const useExams = () => {
  return useContext(ExamsContext);
};