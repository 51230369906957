import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './pages/Login';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Report from './pages/Report';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedExamRoute from "./components/ProtectedExamRoute";
import ExamVideoRoute from "./routes/ExamVideoRoute";
import LearningMaterialRoute from "./routes/LearningMaterialRoute";
import TheoryExamRoute from "./routes/TheoryExamRoute";
import PracticalExamDownloadRoute from "./routes/PracticalExamDownloadRoute";
import PracticalExamSubmitRoute from "./routes/PracticalExamSubmitRoute";
import ExamPdfRoute from "./routes/ExamPdfRoute";
import { AuthLayout } from "./routes/AuthLayout";
import { ModalLayout } from "./routes/ModalLayout";
import { HomeLayout } from "./routes/HomeLayout";
import TheoryResultRoute from "./routes/TheoryResultRoute";
import PracticalResultRoute from "./routes/PracticalResultRoute";
import { ExamsLayout } from "./routes/ExamsLayout";
import PracticalExamIncompletePrerequisitesRoute from "./routes/PracticalExamIncompletePrerequisitesRoute";
import IntroductionVideo from "./pages/IntroductionVideo";
import Certification from "./pages/Certification";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import UserService from "./services/user.service";
import { authAccessToken, removeAccessToken } from "./services/auth-util";
import AnonymousRoute from "./components/AnonymousRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorModal from "./components/ErrorModal";
import InstructionsModal from "./components/InstructionsModal";
import IncompatibleDeviceModal from "./components/IncompatibleDeviceModal";

export const App = () => {
  const [checkIfLoggedIn, setCheckIfLoggedIn] = useState(false);
  const [displayLoggedOutModal, setDisplayLoggedOutModal] = useState(false);

  // Check the user is logged in on the server
  // If not we log them out locally.
  if (authAccessToken() !== '') {
    UserService.getUserDetails().then(
      (response) => {
        if (!response.data.success) {
          removeAccessToken();
          window.location.href = "/";
        }
        else {
          setCheckIfLoggedIn(true);
        }
      },
      (error) => {
        removeAccessToken();
        window.location.href = "/";
      }
    );
  }

  const loggedInInterval = useRef();

  useEffect(() => {
    return () => clearInterval(loggedInInterval.current);
  }, []);

  useEffect(() => {
    if (checkIfLoggedIn) {
      loggedInInterval.current = setInterval(() => {
        if (authAccessToken() !== '') {
          UserService.getUserDetails().then(
            (response) => {
              if (!response.data.success) {
                removeAccessToken();
                setDisplayLoggedOutModal(true);
                setCheckIfLoggedIn(false);
              }
              else {
                setDisplayLoggedOutModal(false);
              }
            },
            (error) => {
              removeAccessToken();
              setDisplayLoggedOutModal(true);
              setCheckIfLoggedIn(false);
            }
          );
        }
        else {
          setDisplayLoggedOutModal(true);
          setCheckIfLoggedIn(false);
        }
      }, 10000);
    }
    else {
      clearInterval(loggedInInterval.current);
      loggedInInterval.current = null;
    }
  }, [checkIfLoggedIn]);

  const defaultErrorMessage = "An unexpected error occurred. Please refresh the page and try again.";

  if (displayLoggedOutModal) {
    return <ErrorModal title="Logged Out" message="You have been logged out. Please log back in to continue."></ErrorModal>;
  }
  else {
    return <BrowserRouter>
      <ErrorBoundary fallback={<ErrorModal title="Financial Modeling Fundamentals" message={defaultErrorMessage} />}>
        <Routes>
          <Route
            element={<AuthLayout />}
          >
            <Route element={<ExamsLayout />}>
              <Route
                element={<HomeLayout />}
              >
                <Route path="/" />
                <Route element={<ModalLayout />}>
                  <Route path="/instructions" element={<InstructionsModal />} />
                  <Route element={<AnonymousRoute />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/request_password_reset" element={<PasswordResetRequest />} />
                    <Route path="/password_reset" element={<PasswordReset />} />
                  </Route>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/intro/video"
                      element={<IntroductionVideo />} />
                    <Route path="/certification"
                      element={<Certification />} />
                    <Route path="/incompatible_device" element={<IncompatibleDeviceModal />} />
                  </Route>
                  <Route element={<ProtectedExamRoute />}>
                    <Route path="/exam/:examId/learning_material" element={<LearningMaterialRoute />} />
                    <Route path="/exam/:examId/theory" element={<TheoryExamRoute />} />
                    <Route path="/exam/:examId/theory/result" element={<TheoryResultRoute />} />
                    <Route path="/exam/:examId/practical/download" element={<PracticalExamDownloadRoute />} />
                    <Route path="/exam/:examId/practical/submit" element={<PracticalExamSubmitRoute />} />
                    <Route path="/exam/:examId/practical/incomplete_prerequisites"
                      element={<PracticalExamIncompletePrerequisitesRoute />} />
                    <Route path="/exam/:examId/practical/result" element={<PracticalResultRoute />} />
                    <Route path="/exam/:examId/video" element={<ExamVideoRoute />} />
                    <Route path="/exam/:examId/pdf" element={<ExamPdfRoute />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  }
};