import {getServerUrl} from "../Util";

const UserAuthenticationService = {
  registerUser: async function(first_name, last_name, email, password) {
    var registerApiUrl = getServerUrl('/api/learning/user/register');
    var formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('password', password);
    return fetch(registerApiUrl, {
      method: 'POST',
      body: formData,
    })
      .then(function(response) {
        var responseJson = response.json()
        return responseJson;
      })
  },

  loginUser: async function(email, password) {
    var loginApiUrl = getServerUrl('/api/learning/user/login');
    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return fetch(loginApiUrl, {
      method: 'POST',
      body: formData,
    })
      .then(function(response) {
        var responseJson = response.json()
        return responseJson;
      })
  },

  logoutUser: async function(accessToken) {
    var loginApiUrl = getServerUrl('/api/learning/user/logout');
    return fetch(loginApiUrl, {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + accessToken},
      body: '',
    })
      .then(function(response) {
        var responseJson = response.json()
        return responseJson;
      })
  },

  requestPasswordReset: async function(email) {
    var passwordResetUrl = getServerUrl('/api/learning/user/reset_password_request');
    var formData = new FormData();
    formData.append('email', email);
    return fetch(passwordResetUrl, {
      method: 'POST',
      body: formData,
    })
      .then(function(response) {
        var responseJson = response.json();
        return responseJson;
      })
  },

  getPasswordRequestDetails: async function(requestUuid) {
    var passwordResetUrl = getServerUrl('/api/learning/user/reset_password_request_details');
    var formData = new FormData();
    formData.append('request_uuid', requestUuid);
    return fetch(passwordResetUrl, {
      method: 'POST',
      body: formData,
    })
      .then(function(response) {
        var responseJson = response.json();
        return responseJson;
      })
  },

  resetPassword: async function(password, requestUuid) {
    var passwordResetUrl = getServerUrl('/api/learning/user/reset_password');
    var formData = new FormData();
    formData.append('password', password);
    formData.append('request_uuid', requestUuid);
    return fetch(passwordResetUrl, {
      method: 'POST',
      body: formData,

    })
      .then(function(response) {
        var responseJson = response.json();
        return responseJson;
      })
  }
};

export default UserAuthenticationService;