const FMFProgressBar = (props) => {
  const {completed} = props;

  const fillerStyles = {
    width: `${completed}%`,
  }

  return (
    <div className="fmf-progress">
      <span className="fmf-progress-label">Progress Score: {`${completed}%`}</span>
      <div className="fmf-progress-bar">
        <div style={fillerStyles}>
        </div>
      </div>
    </div>
  );
};

export default FMFProgressBar;