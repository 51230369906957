import React, { useEffect, useState } from 'react';
import ExamsService from "../services/exams.service";
import { useNavigate } from "react-router";
import { Button, Modal } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

export default function TheoryResult({ exam }) {
  const [theoryResult, setTheoryResult] = useState(null);
  const [error, setError] = useState(false);
  let display = '';
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  useEffect(() => {
    ExamsService.getTheoryResult(exam.id).then(
      (response) => {
        if (response.data.success) {
          setTheoryResult(response.data.data);
        } else {
          setError(true);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  if (error) {
    display = <ErrorModal title="Error" message="Unable to show exam results." />
  } else if (theoryResult) {
    let resultsTableRows = [];
    theoryResult.questionResults.forEach(function (questionResult, index, arr) {
      let rowClass;
      let correctIcon;
      if (questionResult.correct) {
        rowClass = 'correct';
        correctIcon = <FontAwesomeIcon icon={faCheck} />
      } else {
        rowClass = 'incorrect';
        correctIcon = <FontAwesomeIcon icon={faTimes} />
      }
      resultsTableRows.push(<tr className={rowClass} key={index}>
        <td>{correctIcon}</td>
        <td dangerouslySetInnerHTML={{ __html: questionResult.question }}></td>
      </tr>);
    });
    let resultsTable = <table className="theory-results-summary-table">
      <thead>
        <tr>
          <th colSpan="2">Results Summary</th>
        </tr>
      </thead>
      <tbody>
        {resultsTableRows}
      </tbody>
    </table>;

    display = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{exam.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Score: {theoryResult.score}%</p>
        {resultsTable}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  }

  return display;
}