import React from 'react';
import ExamsListItem from "./ExamsListItem";
import ExamsListIntroItem from "./ExamsListIntroItem";
import {useExams} from "../hooks/useExams";

export default function ExamsList() {
  const {getCombinedExamsResults} = useExams();
  const combinedExamsResults = getCombinedExamsResults();
  let completedIntroductionVideo;
  var listItems = [];
  var itemIndex = 1;

  if (combinedExamsResults.dataLoaded) {
    completedIntroductionVideo = false;
    if (combinedExamsResults.introductionVideo) {
      completedIntroductionVideo = combinedExamsResults.introductionVideo.completed;
      listItems.push(<div key="intro" className="col-sm-6 col-lg-3"><ExamsListIntroItem
        introductionVideo={combinedExamsResults.introductionVideo} completedIntroductionVideo={completedIntroductionVideo} itemIndex={itemIndex++}/></div>);
    }

    combinedExamsResults.examResults.forEach(function (combinedExamResult) {
      listItems.push(<div key={combinedExamResult.exam.id} className="col-sm-6 col-lg-3"><ExamsListItem combinedExamResult={combinedExamResult} completedIntroductionVideo={completedIntroductionVideo} itemIndex={itemIndex++}/></div>);
    });

    return (
      <div className="exam-list">
        <div className="row g-3">
          {listItems}
        </div>
      </div>
    )
  }
  else {
    return <div></div>
  }
}