import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {createSearchParams, Link, Navigate, useNavigate} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {useAuth} from "../hooks/useAuth";
import UserAuthenticationService from "../services/UserAuthenticationService";

export default function Register() {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [existingAccount, setExistingAccount] = useState('');
  const {user, login} = useAuth();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  let dialogContent = '';


  const handleRegister = (formValue) => {
    const {first_name, last_name, email, password} = formValue;
    setLoading(true);

    // Call register here...
    UserAuthenticationService.registerUser(first_name, last_name, email, password)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          login(response.data);
        } else {
          if (response.data != null && response.data.hasOwnProperty('existing_account')) {
            if (response.data.existing_account == 'MODANO') {
              // Show existing Modano account dialog
              setFormData({
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
              });
              setExistingAccount('MODANO');
            }
            else {
              // Show existing FMF account dialog.
              // Show existing Modano account dialog
              setFormData({
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
              });
              setExistingAccount('FMF');
            }
          }
          else {
            setErrorMessage(response.error);
          }
        }
        //navigate("/profile");
        //window.location.href = "/profile";
      })
      .catch(() => {
        setLoading(false);
        //if (response.hasOwnProperty('error')) {
        //setErrorMessage(response.error);
        //}
        //else {
        setErrorMessage("Unable to create account. Please try again.");
        // }
      });
  };

  if (user) {
    return <Navigate to="/"/>;
  }

  if (existingAccount === 'FMF') {
    return renderExistingFMFAccountModal(navigate, formData.email, setExistingAccount);
  }
  else if (existingAccount === 'MODANO') {
    return renderExistingModanoAccountModal(navigate, formData.email, setExistingAccount);
  }

  return renderRegisterForm(formData, handleRegister, loading, errorMessage, handleClose)
};

function renderRegisterForm(formData, handleRegister, loading, errorMessage, handleClose) {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required!"),
    last_name: Yup.string().required("This field is required!"),
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  let registerForm = (
    <div className="col-md-12 register-form">
      <p>Create an account to track your progress and store your certificate.</p>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleRegister}
      >
        <Form id="register-form">
          <div className="form-group mb-3">
            <label htmlFor="first_name" className="form-label">First name</label>
            <Field name="first_name" type="text" className="form-control"/>
            <ErrorMessage
              name="first_name"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="last_name" className="form-label">Last name</label>
            <Field name="last_name" type="text" className="form-control"/>
            <ErrorMessage
              name="last_name"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <Field name="email" type="text" className="form-control"/>
            <ErrorMessage
              name="email"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <Field name="password" type="password" className="form-control"/>
            <ErrorMessage
              name="password"
              component="div"
              className="alert alert-danger"
            />
          </div>
          <div className="form-group mb-3">
            <Link to="/login">I already have an account</Link>
          </div>

          {errorMessage && (
            <div className="form-group mb-3">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}

        </Form>
      </Formik>
    </div>
  );

  return (
    <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
      </Modal.Header>
      <Modal.Body>{registerForm}</Modal.Body>
      <Modal.Footer>
        <button form="register-form" type="submit" variant="primary" className="btn btn-primary" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>OK</span>
        </button>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

function renderExistingFMFAccountModal(navigate, email, setExistingAccount) {
  const title = "Existing Account Detected";
  const message = <p>An existing account has been detected using the email address {email}. Would you like to log into this account?</p>;
  return renderExistingAccountModal(title, message, navigate, email, setExistingAccount)
}

function renderExistingModanoAccountModal(navigate, email, setExistingAccount) {
  const title = "Existing Modano Account Detected";
  const message = <p>An existing Modano account has been detected using the email address {email}. Would you like to use this account?</p>;
  const okButtonText = 'Yes';
  const cancelButtonText = 'No';
  return renderExistingAccountModal(title, message, navigate, email, setExistingAccount, okButtonText, cancelButtonText)
}

function renderExistingAccountModal(title, message, navigate, email, setExistingAccount, okButtonText = 'OK', cancelButtonText = 'Cancel') {
  const handleOk = function() {
    navigate({pathname: '/login', search: createSearchParams({email: email}).toString()});
    //navigate('/login');
  }

  const handleClose = function() {
    setExistingAccount('');
  }

  return (
    <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleOk}>{okButtonText}</Button>
        <Button variant="secondary" onClick={handleClose}>{cancelButtonText}</Button>
      </Modal.Footer>
    </Modal>
  );
}
